.loginContainer{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position:fixed;
    height:100%;
    width:100%;
    background-color:#fafafa;
    top:0px;
    left:0px;
}

.login{
    width:400px;
    height:375px;
    border: 1px solid #eeeeee;
    padding: 30px;
    box-sizing:border-box;
    background-color:#ffffff;
    display:flex;
    align-items:center;
    flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.loginInput{
    width:100%;
    margin-top:10px !important;
}

.loginButton{
    width:100%;
    margin-top:70px !important;
    height:40px;
}

.loginRememberMe{
    width:100%
}

.loginRememberMeLabel span{
    font-size:14px !important;
}

.forgotPassword{
    color: #1e88e5;
    font-size: 15px;
    font-weight: 400;
    text-align: right;
    display: block;
    width: 100%;
    margin-top:10px;
    cursor: pointer;
}

.forgotPassword:hover{
    text-decoration: underline;
}