body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}

html {
  padding:0px;
  margin:0px;
  height:100%;
}
#root{
  height:100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.appContainer{
  padding:0px;
  margin:0px;
  height:100%;
  padding-top: 64px;
  box-sizing:border-box;
}

.paddingTopContent{
    padding-top: 45px !important
}

.searchHolder{
  display:-webkit-flex;
  display:flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 350px;
  padding: 5px 10px 5px 10px;
  margin-left:auto;
  background-color: #378e81;
  box-sizing:border-box;
  margin-right:25px;
}

.searchHolder input{
  width:290px;
  color:#ffffff; 
  padding-left:10px;
}

.searchHolder .searchIcon{
  margin-top:2px;
}

.contentContainer{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-grow: 1;
          flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto;  /*adds scroll to this container*/
  background-color:#f7f7f7;
}

.fullHeight{
  height:100%;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.modalBoxMultipleSelect{
  margin-top:20px;
}

.verticalMenu{
  display: 'flex' !important;
  width: 10% !important
}

.baseContainer{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto;  /*adds scroll to this container*/
  padding:20px;
}

.toastSuccess{
  background-color: #1e88e5 !important
}

.toastError{
  background-color: #ef4517 !important
}

@media all and (max-width: 480px){
    .baseContainer{
        padding: 5px
    }
}
.loginContainer{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position:fixed;
    height:100%;
    width:100%;
    background-color:#fafafa;
    top:0px;
    left:0px;
}

.login{
    width:400px;
    height:375px;
    border: 1px solid #eeeeee;
    padding: 30px;
    box-sizing:border-box;
    background-color:#ffffff;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.loginInput{
    width:100%;
    margin-top:10px !important;
}

.loginButton{
    width:100%;
    margin-top:70px !important;
    height:40px;
}

.loginRememberMe{
    width:100%
}

.loginRememberMeLabel span{
    font-size:14px !important;
}

.forgotPassword{
    color: #1e88e5;
    font-size: 15px;
    font-weight: 400;
    text-align: right;
    display: block;
    width: 100%;
    margin-top:10px;
    cursor: pointer;
}

.forgotPassword:hover{
    text-decoration: underline;
}
.sidebar{
    height: 100%; 
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-order: -1;
            order: -1;
    background-color:#ffffff;
    border-right:2px solid #DFDCE3; 
    width: 300px; 
    overflow:auto
  }

.sidebar .collapsePadding{
    padding-left:15px;
    box-sizing:border-box
}

.sidebarClosed{
    background-color:#378e81 !important;
}

.sidebarSubheader{
    display:-webkit-flex;
    display:flex;
    padding:5px !important;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.sidebarSubheader svg:hover{
    cursor:pointer;
}
.width-300{
    width:300px !important;
}

.noPadding{
    padding:0px !important;
}

.homeContainer{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 1;
            flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
    overflow-y: auto;  /*adds scroll to this container*/
    padding:20px;
}

.calendarHolder{
    width:60%;
}

.homeEventsList{
    width: 40%;
    padding: 2px 0px 0px 0px;
    box-sizing:border-box;
}
    .Calendar-grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
  }
  
  .Calendar-header {
    height: 50px;
    background: #ffb22b;
    color: #fff;
    text-align: center;
    line-height: 50px;
    display: -webkit-flex;
    display: flex;
    font-size: 20px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  
  .Calendar-header button {
    width: 50px;
    font-size: 20px;
    border: 0;
    background: transparent;
    color: #ddd;
    cursor: pointer;
  }
  
  .Calendar-grid-item {
    -webkit-flex: 0 1 14.28571%;
            flex: 0 1 14.28571%;
    height: 102px;
    text-align: center;
    border-right: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    padding: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    box-sizing: border-box;
  }
  
  .Calendar-grid-item--current {
    font-weight: 1000 !important;
    background-color:#fc4a1a !important;
    color:#ffffff !important;
  }
  
  .Calendar-grid-item.nextMonth,
  .Calendar-grid-item.prevMonth {
    color: #999 !important;
    background-color:#fefefe !important;
  }
  
  .Calendar-grid-item:nth-child(7n + 1) {
    border-left: 1px solid #ddd;
  }
  
  .Calendar-grid-item:nth-child(-n + 7) {
    border-top: 1px solid #ddd;
  }

.printerCancelledHolder{
    position: fixed;
    width: calc(100% - 200px);
    display: -webkit-flex;
    display: flex;
}

.printerCancelled{
    margin: auto !important;
    width: 40% !important;
    font-size: 40px !important;
    text-align: center;
    border-bottom: 1px solid #000000;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
}

@media print{

    @page {
        size: portrait
    }

    @page {
        margin: 1cm;
    }

    div.reportsPrinter{
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    button{
        display: none !important;
    }

    div.reportRowPrinter{
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
        position: relative;
        height: auto;
    }

    div.reportsDateTimePrinter {
        width: 10% !important;
        border-right: 1px solid #e0e0e0;
        padding-right: 5px;
        padding-top: 10px;
        text-align: center;
        font-size: 13px !important;
    }

    div.reportsDateTimePrinter:before{
        height: auto !important;
    }

    div.reportContentPrinter{
        width: 90% !important;
        font-size: 14px !important;
    }

    div.reportActionPrinter{
        padding-top: 5px !important;
    }

    div.intervalPrinter{
        padding: 0px 0px 0px 10px !important;
        margin-top: 10px;
    }
    
    div.intervalPrinter span{
        padding: 0px !important;
    }

    .printerPage{
        padding: 9px;
    }

    .printerHeader{
        position: fixed;
        top: 0px;
        width: calc(100% - 20px);
    }
    .printerFooter{
        position: fixed;
        bottom: 0px;
        width: calc(100% - 20px);
    }

    .printerCancelledHolder{
        left: 2cm;
        top: 2cm;
    }       

    .header-space{
        height:2.5cm;
    }

    .header-space3cm{
        height:3cm;
    }
    
    .footer-space{
        height: 3cm;
    }
}
