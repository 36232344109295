    .Calendar-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .Calendar-header {
    height: 50px;
    background: #ffb22b;
    color: #fff;
    text-align: center;
    line-height: 50px;
    display: flex;
    font-size: 20px;
    justify-content: space-between;
  }
  
  .Calendar-header button {
    width: 50px;
    font-size: 20px;
    border: 0;
    background: transparent;
    color: #ddd;
    cursor: pointer;
  }
  
  .Calendar-grid-item {
    flex: 0 14.28571%;
    height: 102px;
    text-align: center;
    border-right: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .Calendar-grid-item--current {
    font-weight: 1000 !important;
    background-color:#fc4a1a !important;
    color:#ffffff !important;
  }
  
  .Calendar-grid-item.nextMonth,
  .Calendar-grid-item.prevMonth {
    color: #999 !important;
    background-color:#fefefe !important;
  }
  
  .Calendar-grid-item:nth-child(7n + 1) {
    border-left: 1px solid #ddd;
  }
  
  .Calendar-grid-item:nth-child(-n + 7) {
    border-top: 1px solid #ddd;
  }