
.printerCancelledHolder{
    position: fixed;
    width: calc(100% - 200px);
    display: flex;
}

.printerCancelled{
    margin: auto !important;
    width: 40% !important;
    font-size: 40px !important;
    text-align: center;
    border-bottom: 1px solid #000000;
    transform: rotate(-45deg)
}

@media print{

    @page {
        size: portrait
    }

    @page {
        margin: 1cm;
    }

    div.reportsPrinter{
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    button{
        display: none !important;
    }

    div.reportRowPrinter{
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
        position: relative;
        height: auto;
    }

    div.reportsDateTimePrinter {
        width: 10% !important;
        border-right: 1px solid #e0e0e0;
        padding-right: 5px;
        padding-top: 10px;
        text-align: center;
        font-size: 13px !important;
    }

    div.reportsDateTimePrinter:before{
        height: auto !important;
    }

    div.reportContentPrinter{
        width: 90% !important;
        font-size: 14px !important;
    }

    div.reportActionPrinter{
        padding-top: 5px !important;
    }

    div.intervalPrinter{
        padding: 0px 0px 0px 10px !important;
        margin-top: 10px;
    }
    
    div.intervalPrinter span{
        padding: 0px !important;
    }

    .printerPage{
        padding: 9px;
    }

    .printerHeader{
        position: fixed;
        top: 0px;
        width: calc(100% - 20px);
    }
    .printerFooter{
        position: fixed;
        bottom: 0px;
        width: calc(100% - 20px);
    }

    .printerCancelledHolder{
        left: 2cm;
        top: 2cm;
    }       

    .header-space{
        height:2.5cm;
    }

    .header-space3cm{
        height:3cm;
    }
    
    .footer-space{
        height: 3cm;
    }
}