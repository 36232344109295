.sidebar{
    height: 100%; 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: -1;
    background-color:#ffffff;
    border-right:2px solid #DFDCE3; 
    width: 300px; 
    overflow:auto
  }

.sidebar .collapsePadding{
    padding-left:15px;
    box-sizing:border-box
}

.sidebarClosed{
    background-color:#378e81 !important;
}

.sidebarSubheader{
    display:flex;
    padding:5px !important;
    justify-content: space-between;
}

.sidebarSubheader svg:hover{
    cursor:pointer;
}